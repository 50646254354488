import {
  Component,
  OnChanges,
  Input,
  ViewChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import { OsduObject } from '../models/osdu-object.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Helper } from 'src/app/common/helper.service';
import { Constants } from '../common/constants.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DataPlatformFilterElement } from '../data-platform/models/data-platform-filter-element.model';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-osdu-object-list',
  templateUrl: './osdu-object-list.component.html',
})
export class OsduObjectListComponent implements OnInit, OnChanges {
  mandatoryColumns = [...Constants.objectMandatoryColumns];
  filterHeaderColumnsList: any[] = [];

  @Input() objectList: OsduObject[] = [];
  @Input() length = null;
  @Input() templateColumns = [];
  @Input() selectable = false;
  @Input() selectActionsTemplate: TemplateRef<any>;
  @Input() canSelect: (osduObject: OsduObject) => boolean = () => true;
  @Input() filters: DataPlatformFilterElement[] = [];
  @Input() columnFilterOn: boolean = false;
  selection = new SelectionModel<OsduObject>(true, []);

  Helper = Helper;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = [];
  filterHeaderColumns = [];

  allColumns: string[] = [];

  extraColumns: string[] = [];

  dataSource = new MatTableDataSource();

  filterColumns: string[] = [];
  filterForm: any = FormGroup;

  ngOnInit(): void {
    this.mandatoryColumns.forEach((el) => {
      this.filterHeaderColumnsList.push('_' + el);
    });

    this.filterForm = this.createFormGroup(['_id', '_legal']);

    this.filterForm.valueChanges.subscribe((res: any) => {
      Object.keys(res).forEach((key) => {
        res[key] = res[key].toString().toLowerCase();
      });
      this.dataSource.filter = JSON.stringify(res);
    });
  }

  ngOnChanges(): void {
    if (!this.objectList && !this.filters) return;

    this.dataSource = new MatTableDataSource();

    this.extraColumns = [];

    const headers = Helper.getOsduObjectFieldKeys(this.objectList);

    this.filterColumns = [];

    this.extraColumns = headers
      .filter((el) => !this.mandatoryColumns.find((m) => m === el))
      .sort(this.sortColumns);

    const filterSet = new Set<string>();
    this.filters
      .filter((el) => el.selectedField)
      .forEach((el) => filterSet.add(el.selectedField));

    filterSet.forEach((el) => {
      if (!this.extraColumns.find((m) => m === el)) {
        this.extraColumns.push(el);
      }
    });

    this.filterColumns = Array.from(filterSet).sort(this.sortColumns);

    this.allColumns = [...this.mandatoryColumns, ...headers];

    this.dataSource = new MatTableDataSource(this.objectList);

    this.dataSource.paginator = this.paginator;

    this.selection = new SelectionModel<OsduObject>(true, []);
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.customFilter;
    this.setSortingAccessor();
  }

  customFilter = (data: any, filter: string) => {
    const filterData = JSON.parse(filter);
    let ok: boolean = true;
    for (let key in filterData) {
      if (filterData[key]) {
        let rmvunderscore = key.slice(1);
        if (rmvunderscore.includes('data.')) {
          let dataKey = rmvunderscore.slice(5);
          if (data.data && typeof data.data[dataKey] === 'number') {
            let numberData = data.data[dataKey].toString();
            ok = ok && numberData.toLowerCase().indexOf(filterData[key]) >= 0;
          } else if (data.data && data.data[dataKey] === undefined) {
            ok = ok && false;
          } else if (data.data && typeof data.data[dataKey] === 'string') {
            ok =
              ok &&
              data.data[dataKey].toLowerCase().indexOf(filterData[key]) >= 0;
          } else if (data.data && data.data[dataKey] === null) {
            ok = ok && false;
          }
        } else {
          if (key == '_legal') {
            ok =
              ok &&
              data.legal.legaltags[0]
                .toLowerCase()
                .indexOf(filterData._legal) >= 0;
          } else if (typeof data[rmvunderscore] === 'number') {
            let numberData = data[rmvunderscore].toString();
            ok = ok && numberData.toLowerCase().indexOf(filterData[key]) >= 0;
          } else if (typeof data[rmvunderscore] === 'string') {
            ok =
              ok &&
              data[rmvunderscore].toLowerCase().indexOf(filterData[key]) >= 0;
          }
        }
      }
    }
    return ok;
  };

  getExtraField(element: OsduObject, key: string) {
    return Helper.getFieldFromDottedString(element, key);
  }

  private sortColumns(c1: string, c2: string) {
    return c1.localeCompare(c2);
  }

  isSimpleData(element, key) {
    const field = Helper.getFieldFromDottedString(element, key);
    return (
      !field ||
      typeof field === 'string' ||
      typeof field === 'number' ||
      typeof field === 'boolean'
    );
  }

  getObjectParentString(element: OsduObject, key: string) {
    return `${element.id} > ${key.split('.').join(' > ')}`;
  }

  displayedColumnsChange(event: string[]) {
    this.displayedColumns = [
      ...(this.selectable ? ['select'] : []),
      ...event,
      ...this.templateColumns.map((el) => el.id),
    ];
    this.filterHeaderColumns = [
      ...(this.selectable ? ['select'] : []),
      ...event.map((el) => '_' + el),
      ...this.templateColumns.map((el) => '_' + el.id),
    ];
    this.filterHeaderColumnsList = this.filterHeaderColumns;
    this.removeControlsNotInList(this.filterHeaderColumns, this.filterForm);
    this.filterHeaderColumns.forEach((controlName) => {
      this.addControlToFormGroup(controlName, this.filterForm);
    });
  }

  createFormGroup(controlNames: string[]): FormGroup {
    const group: any = {};
    controlNames.forEach((controlName) => {
      group[controlName] = new FormControl('');
    });
    return new FormGroup(group);
  }

  addControlToFormGroup(controlName: string, formGroup: FormGroup) {
    if (!formGroup.contains(controlName)) {
      if (
        controlName !== '_actions' &&
        controlName !== '_ancestry' &&
        (controlName == '_legal' ||
          this.isSimpleData(this.objectList[0], controlName.substring(1)))
      ) {
        formGroup.addControl(controlName, new FormControl(''));
      }
    }
  }

  removeControlsNotInList(controlNames: string[], formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((controlName) => {
      if (!controlNames.includes(controlName)) {
        formGroup.removeControl(controlName);
      }
    });
  }

  isControlPresent(column) {
    return this.filterForm.contains(column);
  }

  private setSortingAccessor() {
    this.dataSource.sortingDataAccessor = (item: OsduObject, property) => {
      return this.getExtraField(item, property);
    };
  }
}
