import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-expand-snackbar',
  templateUrl: './expand-snackbar.component.html',
  styleUrls: ['./expand-snackbar.component.css'],
})
export class ExpandSnackbarComponent {
  header: string;
  body: any;
  plainText: boolean = false;
  isExpanded: boolean = false;

  constructor(
    public snackBarRef: MatSnackBarRef<ExpandSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    this.header = data.header;
    this.body = data.body;
    this.plainText = data.plainText;
  }

  getHeaderSuffix(): string {
    if (this.canExpand()) {
      return this.isExpanded ? '[show less]' : '[show more]';
    }
    return '';
  }

  canExpand(): boolean {
    return !!this.body;
  }

  toggleBody(): void {
    if (!this.canExpand()) {
      return;
    }
    this.isExpanded = !this.isExpanded;
  }

  closeSnackbar(event: Event): void {
    event.stopPropagation();
    this.snackBarRef.dismiss();
  }
}
