import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'schemaName',
})
export class SchemaNamePipe implements PipeTransform {
  transform(value: string) {
    let schemaName = value;

    try {
      const result = /--(?<TypeName>\w+):(?<Version>\d+.\d+.\d+)$/.exec(value);
      schemaName = `${result.groups.TypeName} ${result.groups.Version}`;
    } catch (error) {}

    return schemaName;
  }
}
