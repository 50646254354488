<div class="d-flex-inline align-items-center filter-element-container">
  <div>
    <app-auto-complete
      label="Select field"
      [getSuggestions]="getFieldSuggestions.bind(this)"
      [selected]="filters.selectedField"
      (inputChange)="selectedFieldChange($event)"
      (selectedChange)="selectedFieldChange($event)"
    >
    </app-auto-complete>
  </div>

  <app-auto-complete
    label="Select value"
    [getSuggestions]="getSuggestions.bind(this)"
    [(selected)]="filters.value"
    [displayFn]="displayFn"
    (selectedChange)="fieldValueChange($event)"
    (inputChange)="fieldValueChange($event)"
    [disabled]="!filters.selectedField"
    class="autocomplete-field"
  ></app-auto-complete>
</div>
