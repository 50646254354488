<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ label }}</mat-label>
  <div *ngIf="enableChips; else noChips">
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let option of selectedElements"
        (removed)="remove(option)"
        class="mat-chip-selected"
      >
        {{ displayFn(option) }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        #colInput
        [formControl]="formControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        [required]="required"
      />
    </mat-chip-list>
  </div>
  <ng-template #noChips>
    <input
      matInput
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [required]="required"
      (keyup.enter)="onEnterKey()"
    />
  </ng-template>
  <mat-icon color="primary" matPrefix>{{ icon }}</mat-icon>
  <button mat-icon-button color="warn" (click)="clear()" matSuffix>
    <mat-icon>clear</mat-icon>
  </button>
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="optionSelected($event)"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      <div class="mat-option-complex-text">
        {{ displayFn(option) }}
        <span> {{ displaySubTextFn(option) }}</span>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
