import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  startWith,
  switchMap,
  debounceTime,
  tap,
  takeUntil,
  skip,
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Constants } from '../common/constants.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { AutoCompleteBase } from './base-auto-complete';
@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.css'],
})
export class AutoCompleteComponent<T>
  extends AutoCompleteBase<T>
  implements OnInit, OnChanges, OnDestroy
{
  formControl: FormControl = new FormControl('');
  selectedElements: Set<T> = new Set<T>();

  @Input() displayFn: (element: T) => string = (element: T) =>
    element?.toString() ?? '';

  @Input() displaySubTextFn: (element: T) => string = (element: T) =>
    element?.toString() == '[object Object]' ? '' : element?.toString();

  @Output() enterKeyPressed = new EventEmitter<string>();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() getSuggestions: (filter: string) => Observable<T[]>;
  @Input() selected: T[];
  @Input() label: string = '';
  @Input() icon: string = 'search';
  @Input() required = false;
  @Input() disabled = false;
  @Output() selectedChange = new EventEmitter<T[]>();
  @Output() inputChange = new EventEmitter<string>();
  @ViewChild('colInput') colInput: ElementRef<HTMLInputElement>;
  @Input() enableChips = false;

  filteredOptions: Observable<T[]>;

  private _destroyed = new Subject<unknown>();

  optionSelected(event: MatAutocompleteSelectedEvent) {
    if (this.enableChips) {
      const selectedOption = event.option.value;
      if (!this.selectedElements.has(selectedOption)) {
        this.selectedElements.add(selectedOption);
      }
      this.colInput.nativeElement.value = '';
      this.formControl.setValue('');
      this.emitSelectedElements();
    } else {
      this.selected = event.option.value;
      this.selectedChange.emit(this.selected);
    }
  }

  onEnterKey(): void {
    this.enterKeyPressed.emit(this.formControl.value);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const newUser = this.stringToUser(value);
      this.selectedElements.add(newUser);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.formControl.setValue('');

    this.emitSelectedElements();
  }

  stringToUser(value: string): T {
    // Implement this method to convert the string value to a user object
    return value as unknown as T;
  }

  clear() {
    this.selected = null;
    this.formControl.setValue('');
    this.selectedChange.emit(this.selected);
  }

  remove(user: T): void {
    if (this.selectedElements.has(user)) {
      this.selectedElements.delete(user); // Remove from Set
      this.emitSelectedElements(); // Emit as array
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected)
      this.formControl.setValue(changes.selected.currentValue, {
        emitEvent: false,
      });

    if (changes.disabled) {
      changes.disabled.currentValue
        ? this.formControl.disable()
        : this.formControl.enable();
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  ngOnInit(): void {
    const baseObservable = this.formControl.valueChanges.pipe(
      takeUntil(this._destroyed),
      debounceTime(Constants.debounceTime),
      startWith('')
    );

    this.filteredOptions = baseObservable.pipe(
      switchMap((filter) => this.getSuggestions(filter))
    );

    baseObservable
      .pipe(
        skip(1),
        tap((filter) => this.inputChange.emit(filter))
      )
      .subscribe();
  }

  private emitSelectedElements() {
    this.selectedChange.emit(Array.from(this.selectedElements));
  }
}
