<div class="snackbar-container">
  <div
    class="snackbar-header"
    [ngClass]="{ toggle: canExpand() }"
    (click)="toggleBody()"
  >
    <span>{{ header }} {{ getHeaderSuffix() }}</span>
    <button mat-icon-button (click)="closeSnackbar($event)">×</button>
  </div>
  <div class="snackbar-body" *ngIf="isExpanded">
    <div class="snackbar-content">
      <pre *ngIf="!plainText">{{ body | json }}</pre>
      <pre *ngIf="plainText">{{ body }}</pre>
    </div>
  </div>
</div>
